// Global annotations
////
/// @group mixins
/// @access private
////

// Navigation tabs styling
@mixin navigation-tabs(
  $nav-tabs-link-color,
  $nav-tabs-link-focus-border-color,
  $nav-tabs-link-hover-border-color,
  $nav-tabs-link-active-border-color,
  $nav-tabs-link-border-color,
  $nav-tabs-link-disabled-color,
  $nav-tabs-link-active-hover-border-color,
  $specificity: ""
) {
  #{$specificity} .nav-tabs {
    border-bottom-color: $nav-tabs-link-border-color;
    .nav-link {
      color: $nav-tabs-link-color;
      background-color: $color-background-none;
      &:focus {
        &:before {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: $nav-tabs-link-border-radius;
          border: $nav-tabs-link-focus-border-color;
          content: "";
        }
      }

      &:disabled {
        opacity: 1;
        color: $nav-tabs-link-disabled-color;
      }

      &:hover {
        &:after {
          background-color: $nav-tabs-link-hover-border-color;
        }
      }
    }

    .nav-link.active {
      &:after {
        background-color: $nav-tabs-link-active-border-color;
      }

      &:hover {
        &:after {
          background-color: $nav-tabs-link-active-hover-border-color;
        }
      }
    }
  }
}
